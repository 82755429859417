<template>
  <div>
    <c-search-box ref="searchbox" @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 사고등급 -->
          <c-select
            type="search"
            codeGroupCd="IIM_GRADE_CD"
            itemText="codeName"
            itemValue="code"
            name="accidentGradeCd"
            label="LBL00001508"
            v-model="searchParam.accidentGradeCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <!-- 사고종류 -->
          <c-multi-select
            codeGroupCd="IIM_KIND_CD"
            :editable="editable"
            itemText="codeName"
            itemValue="code"
            maxValues="3"
            name="accidentKindCdList"
            label="LBL0002301"
            v-model="searchParam.accidentKindCdList"
          ></c-multi-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-process
            :editable="editable"
            label="LBLPROCESS"
            name="processCd"
            v-model="searchParam.processCd">
          </c-process>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 진행상태 -->
          <c-select
            type="search"
            :stepperGrpCd="popupParam.accidentKind === 'NEAR' ? 
            'IIM_STATUS_NEAR_CD' : 'IIM_STATUS_PROCESS_CD'"
            itemText="stepperMstNm"
            itemValue="stepperMstCd"
            name="accidentStatusCd"
            label="LBLPROGRESS"
            v-model="searchParam.accidentStatusCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 발생부서 -->
          <c-dept
            type="search"
            name="occurrenceDeptCd"
            label="LBL0002302"
            v-model="searchParam.occurrenceDeptCd"
          ></c-dept>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 발생일 -->
          <c-datepicker
            :range="true"
            name="period"
            label="LBL00001504"
            v-model="period"
          ></c-datepicker>
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      :title="`${title} ${$comm.getLangLabel('LBLLIST')}`"
      :columns="grid.columns"
      :data="grid.data"
      :merge="grid.merge"
      :gridHeight="grid.height"
      :selection="popupParam.type"
      :rowKey="rowKey"
      @linkClick="linkClick"
      @rowDblclick="rowDblclick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <div>
          <q-btn-group outline>
            <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
            <c-btn label="LBLSELECT" icon="check" @btnClicked="select" />
          </q-btn-group>
        </div>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: "accident-pop",
  props: {
    popupParam: {
      type: Object,
      default: function() {
        return {
          type: 'multiple',
          accidentKind: 'PROCESS' // 공정사고 NEAR : 아차사고
        }
      },
    },
    contentHeight: {
      type: [String, Number],
      default: function() {
        return 'auto'
      },
    }
  },
  data() {
    return {
      rowKey: 'iimAccidentId',
      columns1: [
        {
          name: "plantName",
          field: "plantName",
          label: "LBLPLANT",
          style: 'width:100px',
          align: "center",
          sortable: true,
        },
        {
          name: "accidentNo",
          field: "accidentNo",
          // 사고번호
          label: "LBL0002305",
          style: 'width:120px',
          align: "center",
          sortable: true,
        },
        {
          name: "accidentName",
          field: "accidentName",
          // 사고명
          label: "LBL0002306",
          style: 'width:200px',
          align: "left",
          sortable: true,
          type: "link",
        },
        {
          name: "processName",
          field: "processName",
          label: "LBLPROCESS",
          style: 'width:100px',
          align: "center",
          sortable: true,
        },
        {
          name: "accidentStatusName",
          field: "accidentStatusName",
          style: 'width:100px',
          label: "LBLPROGRESS",
          align: "center",
          sortable: true,
        },
        {
          name: "accidentKindName",
          field: "accidentKindName",
          style: 'width:150px',
          // 사고종류
          label: "LBL0002301",
          align: "center",
          sortable: true,
        },
        {
          name: "accidentGradeName",
          field: "accidentGradeName",
          // 사고등급
          label: "LBL00001508",
          style: 'width:100px',
          align: "center",
          sortable: true,
        },
        {
          name: "occurrenceDate",
          field: "occurrenceDate",
          style: 'width:100px',
          // 발생일시
          label: "LBL0002309",
          align: "center",
          sortable: true,
        },
      ],
      columns2: [
        {
          name: "plantName",
          field: "plantName",
          label: "LBLPLANT",
          style: 'width:100px',
          align: "center",
          sortable: true,
        },
        {
          name: "iimNearAccidentNo",
          field: "iimNearAccidentNo",
          // 사고번호
          label: "LBL0002305",
          style: 'width:120px',
          align: "center",
          sortable: true,
        },
        {
          name: "iimNearAccidentName",
          field: "iimNearAccidentName",
          // 사고명
          label: "LBL0002306",
          style: 'width:200px',
          align: "left",
          sortable: true,
          type: "link",
        },
        {
          name: "processName",
          field: "processName",
          label: "LBLPROCESS",
          style: 'width:100px',
          align: "center",
          sortable: true,
        },
        {
          name: "iimStatusNearName",
          field: "iimStatusNearName",
          style: 'width:100px',
          label: "LBLPROGRESS",
          align: "center",
          sortable: true,
        },
        {
          name: "iimNearKindName",
          field: "iimNearKindName",
          style: 'width:150px',
          // 사고종류
          label: "LBL0002301",
          align: "center",
          sortable: true,
        },
        {
          name: "iimGradeName",
          field: "iimGradeName",
          // 사고등급
          label: "LBL00001508",
          style: 'width:100px',
          align: "center",
          sortable: true,
        },
        {
          name: "occurrenceDt",
          field: "occurrenceDt",
          style: 'width:100px',
          // 발생일시
          label: "LBL0002309",
          align: "center",
          sortable: true,
        },
      ],
      grid: {
        columns: [],
        data: [],
        height: '500px'
      },
      searchParam: {
        plantCd: null,
        startYmd: '',
        endYmd: '',
        accidentGradeCd: null,
        occurrenceDeptCd: '',
        accidentKindCdList: [],
        accidentStatusCd: null,
        processCd: '',
      },
      title: '',
      period: ['', ''],
      editable: true,
      searchUrl: "",
      popupOptions: {
        target: null,
        title: "",
        visible: false,
        isFull: true,
        top: "",
        param: {},
        closeCallback: null,
      },
      isResult: false,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  watch: {
    contentHeight() {
      let height = (this.contentHeight - this.$refs['searchbox'].$el.offsetHeight - 50);
      if (height < 400) {
        height = 500;
      }
      this.grid.height = height + 'px'
    }
  },
  beforeDestroy() {},
  methods: {
    init() {
      // role setting
      if (this.popupParam.accidentKind === 'NEAR') {
        this.grid.columns = this.columns2;
        this.rowKey = 'iimNearAccidentId';
        this.listUrl = selectConfig.sop.iim.accident.near.list.url;
        this.title = this.$comm.getLangLabel('LBL00001511') // 아차사고
      } else {
        this.grid.columns = this.columns1;
        this.rowKey = 'iimAccidentId';
        this.listUrl = selectConfig.sop.iim.accident.process.list.url;
        this.title = this.$comm.getLangLabel('LBL00001512') // 공정사고
      }
      // url setting
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      this.searchParam.startYmd = this.period[0];
      this.searchParam.endYmd = this.period[1];

      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    /* eslint-disable no-unused-vars */ 
    onItemClick() {
      this.linkClick(null);
    },
    linkClick(row, col, index) {
      if (this.popupParam.accidentKind === 'NEAR') {
        this.popupOptions.target = () => import(`${"./near/nearAccidentDetail.vue"}`);
        this.popupOptions.param = {
          iimNearAccidentId: row ? row.iimNearAccidentId : '',
          stepCd: row ? row.iimStatusNearCd : '',
          count: 0,
        };
      } else {
        this.popupOptions.target = () => import(`${"./processAccidentDetail.vue"}`);
        this.popupOptions.param = {
          iimAccidentId: row ? row.iimAccidentId : '',
          stepCd: row ? row.accidentStatusCd : '',
          count: 0,
        };
      }
      this.popupOptions.title = `${this.title} ${this.$comm.getLangLabel('LBLDETAIL')}`; // 사고 상세
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
    select() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$emit('closePopup', selectData);
      }
    },
    rowDblclick(row) {
      if (this.popupParam.type === 'single') {
        this.$emit('closePopup', [row]);
      }
    },
  },
};
</script>
